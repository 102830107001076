import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import startPageQuery from './StartPageQuery.gql';
import { ContentRender } from '../ContentRender/ContentRender';
import Head from '@jetshop/core/components/Head';
import StartPageLoadingState from './StartPageLoadingState';
import { Render } from '../StartPage-old/Render/Render';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading, data } = result;
  if (loading || !data) return <StartPageLoadingState />;

  const legacyStartPage =
    data &&
    data.startPage &&
    data.startPage.items &&
    data.startPage.items.length;

  if (data && data.startPage)
    return (
      <StartPageWrapper>
        {result.data.startPage && (
          <Head
            data={result.data.startPage}
            route={result.data.startPage.primaryRoute}
          />
        )}
        {legacyStartPage ? (
          <Render result={result} />
        ) : (
          <ContentRender data={data.startPage.data} />
        )}
      </StartPageWrapper>
    );
  else return null;
};

export default StartPage;
